/* tslint:disable:max-line-length */
export default {
  'first-name-placeholder': 'Geben Sie hier Ihren Vornamen an...',
  'last-name-placeholder': 'Geben Sie hier Ihren Nachnamen an...',
  'register-your-account-now': 'Registrieren Sie jetzt Ihr Konto',
  'address-lookup-placeholder': 'Suchen Sie nach Ihrer Adresse...',
  'address-line1-placeholder': 'Geben Sie hier Ihre Adresse an...',
  'address-line2-placeholder': 'Geben Sie hier Ihre Adresse an...',
  'address-city-placeholder': 'Geben Sie hier Ihre Stadt an...',
  'address-lookup-town-city': 'Stadt',
  'address-zip-code-placeholder': 'Geben Sie hier Ihre Postleitzahl an...',
  'address-lookup-postal-code-zip': 'Postleitzahl',
  'address-lookup-enter-manually': `Manuell eingeben`,
  'email-placeholder': 'Geben Sie hier Ihre Email an...',
  'phone-placeholder': 'Geben Sie hier Ihre Telefonnummer an...',
  'username-placeholder': 'Geben Sie hier Ihren Benutzernamen an...',
  'password-placeholder': 'Geben Sie hier Ihr Passwort an...',
  'step-1-pretext': 'Schritt 1 von 3',
  'step-2-pretext': 'Schritt 2 von 3',
  'step-3-pretext': 'Schritt 3 von 3',
  'step-1-post-text': 'Schritt 1 von 3',
  'step-2-post-text': 'Schritt 2 von 3 ',
  'step-3-post-text': 'Betreten Sie die Lounge',
  'date-format': 'TT/MM/JJJJ',
  phone: 'Handynummer',
  'marketing-preferences-title': 'Kommunikationspräferenzen',
  'marketing-preferences-pre-text':
    'Vegas Lounge bietet seinen Spielern einzigartige Sonderangebote. Um weitere Informationen über diese Angebote zu erhalten, geben Sie bitte unten Ihre Kommunikationspräferenzen an: ',
  'marketing-preferences-post-text':
    'Sie können Ihre Kommunikationspräferenzen jederzeit in Ihrem Bereich Mein Konto aktualisieren.',
  'marketing-preferences-cross-marketing-description':
    'Ich möchte über exklusive Angebote Ihres <strong>Casino-Netzwerks</strong> informiert werden.',
  'marketing-preferences-contact-options-email':
    'Ich möchte per <strong>Email</strong> über Angebote informiert werden',
  'marketing-preferences-contact-options-sms': 'Ich möchte per <strong>SMS</strong> über Angebote informiert werden',
  'marketing-preferences-contact-options-phone':
    'Ich möchte per <strong>Telefon</strong> über Angebote informiert werden',
  'marketing-preferences-contact-options-post': 'Ich möchte per <strong>Post</strong> über Angebote informiert werden',
  'terms-and-conditions-privacy-policy-and-funds-policy':
    'Ich bestätige, dass ich <span class="age-text">mindestens 18 Jahre alt bin</span> und dass ich die <a href="/?page=terms" target="_blank" class="ui-link" >Allgemeinen Geschäftsbedingungen</a>, <a href="/?page=privacy" target="_blank" class="ui-link">Datenschutz-Richtlinien und den Cookie-Hinweis</a> und die <a href="/?page=terms#funds-policy" target="_blank" class="ui-link" >Geldpolitik</a> akzeptiere.',
  'sign-up': 'Konto erstellen',
};
