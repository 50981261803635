<div *ngIf="!isEscapeVegasPromoActive && !isDecPromoActive && !isJanPromoActive && !isFebPromoActive" class="video-content">
  <video #video (loadedmetadata)="video.muted = true" muted playsinline autoplay preload="auto" [loop]="_videoLoop">
    <source [src]="isMobile && videoMobileSrc ? videoMobileSrc : videoSrc" type="video/mp4" />
  </video>
</div>

<!--Start EscapeVegas Promo banner-->
<div *ngIf="isEscapeVegasPromoActive" id="escape-vegas" class="promo-banner">
  <div class="container promotion-content">
    <div class="promotion-content-flex">
      <div>
        <h2 class="mb-1">{{ escapevegas1 | vegastranslate }}</h2>
        <h1 class="mb-1">{{ escapevegas2 | vegastranslate }}</h1>
        <a class="btn btn-primary btn-lg" [href]="getLearnMoreLink()" target="_blank">{{ escapevegas3 | vegastranslate }}</a>
      </div>
    </div>
    <div class="significant-terms fw-light">
      <p>
        <a href="/playerprotection">18+</a>&nbsp; <a href="/playerprotection">{{ pragmatic6 | vegastranslate }}</a
        >. <a [href]="getSigTermsLink()" target="_blank">{{ pragmatic7 | vegastranslate }}</a
        >.
        <a href="https://www.gamblingtherapy.org/" target="_blank">www.GamblingTherapy.org</a>
      </p>
    </div>
  </div>
</div>
<!--End EscapeVegas Promo banner-->

<!--Start Dec Promo banner-->
<div *ngIf="isDecPromoActive" id="cruise-promo-banner" class="promo-banner december-banner">
  <div class="container promotion-content">
    <div class="promotion-content-flex">
      <div>
        <h1 class="mb-1">{{ cruise1 | vegastranslate }}</h1>
        <h2>{{ cruise2 | vegastranslate }}<br />{{ cruise3 | vegastranslate }}</h2>
        <a class="btn btn-primary btn-lg" [href]="getLearnMoreLink()" target="_blank">{{ cruise4 | vegastranslate }}</a>
      </div>
    </div>
    <div class="significant-terms fw-light">
      <p>
        <a href="/playerprotection">18+</a> {{ cruise5 | vegastranslate }} {{ cruise6 | vegastranslate }} Read
        <a [href]="getSigTermsLink()" target="_blank">full terms</a> for all details and exclusions.
        <a href="/playerprotection">Play responsibly</a>.
        <a href="https://www.gamblingtherapy.org/" target="_blank">www.GamblingTherapy.org</a>
      </p>
    </div>
  </div>
</div>
<!--End Dec Promo banner-->

<!--Start Jan Promo banner-->
<div *ngIf="isJanPromoActive" id="cruise-promo-banner" class="promo-banner january-banner">
  <div class="container promotion-content">
    <div class="promotion-content-flex">
      <div>
        <h1 class="mb-1">{{ cruise1 | vegastranslate }}</h1>
        <h2>{{ cruise2 | vegastranslate }}<br />{{ cruise3 | vegastranslate }}</h2>
        <a class="btn btn-primary btn-lg" [href]="getLearnMoreLink()" target="_blank">{{ cruise4 | vegastranslate }}</a>
      </div>
    </div>
    <div class="significant-terms fw-light">
      <p>
        <a href="/playerprotection">18+</a> {{ cruise5 | vegastranslate }} {{ cruise6 | vegastranslate }} Read
        <a [href]="getSigTermsLink()" target="_blank">full terms</a> for all details and exclusions.
        <a href="/playerprotection">Play responsibly</a>.
        <a href="https://www.gamblingtherapy.org/" target="_blank">www.GamblingTherapy.org</a>
      </p>
    </div>
  </div>
</div>
<!--End Jan Promo banner-->

<!--Start Feb Promo banner-->
<div *ngIf="isFebPromoActive" id="cruise-promo-banner" class="promo-banner february-banner">
  <div class="container promotion-content">
    <div class="promotion-content-flex">
      <div>
        <h1 class="mb-1">{{ cruise1 | vegastranslate }}</h1>
        <h2>{{ cruise2 | vegastranslate }}<br />{{ cruise3 | vegastranslate }}</h2>
        <a class="btn btn-primary btn-lg" [href]="getLearnMoreLink()" target="_blank">{{ cruise4 | vegastranslate }}</a>
      </div>
    </div>
    <div class="significant-terms fw-light">
      <p>
        <a href="/playerprotection">18+</a> {{ cruise5 | vegastranslate }} {{ cruise6 | vegastranslate }} Read
        <a [href]="getSigTermsLink()" target="_blank">full terms</a> for all details and exclusions.
        <a href="/playerprotection">Play responsibly</a>.
        <a href="https://www.gamblingtherapy.org/" target="_blank">www.GamblingTherapy.org</a>
      </p>
    </div>
  </div>
</div>
<!--End Feb Promo banner-->

<div class="container">
  <div class="whg-banner d-flex">
    <div class="whg-banner__text">
      <h2 class="fw-normal">{{ text1 | vegastranslate }}</h2>
      <h4 class="text-uppercase fw-light m-0">{{ step1 | vegastranslate }}</h4>
      <h1 class="text-uppercase m-0">
        {{ text2 | vegastranslate: { currency: currency } }}<br />{{ text3 | vegastranslate: { currency: currency } }}
      </h1>
      <h4 class="text-uppercase fw-light m-0">{{ step2 | vegastranslate }}</h4>
      <h3 class="text-uppercase fw-light mt-1 fst-italic">{{ text4 | vegastranslate }}</h3>
    </div>
    <div class="d-flex">
      <a class="btn btn-primary btn-lg m-auto" [href]="link">{{ buttonText | vegastranslate }}</a>
    </div>
  </div>
</div>
<div></div>
