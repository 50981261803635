/* tslint:disable:max-line-length */
export default {
  'first-name-placeholder': 'Your first name goes here...',
  'last-name-placeholder': 'Your last name goes here...',
  'register-your-account-now': 'Register your account now',
  'address-lookup-placeholder': 'Search for your address...',
  'address-line1-placeholder': 'Your address goes here...',
  'address-line2-placeholder': 'Your address goes here...',
  'address-city-placeholder': 'Your city goes here...',
  'address-lookup-town-city': 'city',
  'address-zip-code-placeholder': 'Your zip code goes here...',
  'address-lookup-postal-code-zip': 'zip code',
  'address-lookup-enter-manually': `Enter manually`,
  'email-placeholder': 'Your email goes here...',
  'phone-placeholder': 'Your number goes here...',
  'username-placeholder': 'Your username goes here...',
  'password-placeholder': 'Your password goes here...',
  'step-1-pretext': 'Step 1 of 3',
  'step-2-pretext': 'Step 2 of 3',
  'step-3-pretext': 'Step 3 of 3',
  'step-1-post-text': 'Step 1 of 3',
  'step-2-post-text': 'Step 2 of 3 ',
  'step-3-post-text': 'Enter the Lounge',
  'date-format': 'DD/MM/YYYY',
  phone: 'mobile number',
  'marketing-preferences-title': 'Communication Preferences',
  'marketing-preferences-pre-text':
    'Vegas Lounge provides its players with unique special offers. To receive further information about these offers, please specify your communication preferences below: ',
  'marketing-preferences-post-text':
    'You can update your communication preferences at any time in your My Account section.',
  'marketing-preferences-cross-marketing-description':
    'I want to hear about exclusive offers from your <strong>casino network</strong>.',
  'marketing-preferences-contact-options-email': 'I want to hear about offers via <strong>email</strong>',
  'marketing-preferences-contact-options-sms': 'I want to hear about offers via <strong>sms</strong>',
  'marketing-preferences-contact-options-phone': 'I want to hear about offers via <strong>phone</strong>',
  'marketing-preferences-contact-options-post': 'I want to hear about offers via <strong>post</strong>',
  'terms-and-conditions-privacy-policy-and-funds-policy':
    'I confirm that I am <span class="age-text">at least 18 years of age</span> and that I accept the <a href="/?page=terms" target="_blank" class="ui-link">Terms and Conditions</a>, <a href="/?page=privacy" target="_blank" class="ui-link">Privacy and Cookie Notice</a> and <a href="/?page=terms#funds-policy" target="_blank" class="ui-link">Funds Policy</a>.',
  'sign-up': 'Create account',
};
