export interface RestrictionCriteriasConfig {
  text?: string;
  icon?: string;
  excludedJurisdictions?: string[];
  includedOnlyLanguages?: string[];
  excludedOnlyLanguages?: string[];
}

export enum ImageTypes {
  Portrait = '/images/portrait/',
  Landscape = '/images/games/',
  Mobile = '/images/mobile/icons/new-icons/',
}
