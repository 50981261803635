/* tslint:disable:max-line-length */
export default {
  'first-name-placeholder': 'Etunimesi tulee tähän...',
  'last-name-placeholder': 'Sukunimesi tulee tähän...',
  'register-your-account-now': 'Rekisteröi tilisi nyt',
  'address-lookup-placeholder': 'Etsi osoitteesi...',
  'address-line1-placeholder': 'Osoitteesi tulee tähän...',
  'address-line2-placeholder': 'Osoitteesi tulee tähän...',
  'address-city-placeholder': 'Kaupunkisi tulee tähän...',
  'address-lookup-town-city': 'kaupunki',
  'address-zip-code-placeholder': 'Postinumerosi tulee tähän...',
  'address-lookup-postal-code-zip': 'postinumero',
  'address-lookup-enter-manually': `Syötä manuaalisesti`,
  'email-placeholder': 'Sähköpostisi tulee tähän…',
  'phone-placeholder': 'Numerosi tulee tähän…',
  'username-placeholder': 'Käyttäjätunnuksesi tulee tähän…',
  'password-placeholder': 'Salasanasi tulee tähän…',
  'step-1-pretext': 'Vaihe 1/3',
  'step-2-pretext': 'Vaihe 2/3',
  'step-3-pretext': 'Vaihe 3/3',
  'step-1-post-text': 'Vaihe 1/3',
  'step-2-post-text': 'Vaihe 2/3 ',
  'step-3-post-text': 'Astu aulaan',
  'date-format': 'PP/KK/VVVV',
  phone: 'matkapuhelinnumero',
  'marketing-preferences-title': 'Viestintäasetukset',
  'marketing-preferences-pre-text':
    'Vegas Lounge tarjoaa pelaajilleen ainutlaatuisia erikoistarjouksia. Jos haluat lisätietoja näistä tarjouksista, määritä viestintäasetuksesi alla:',
  'marketing-preferences-post-text': 'Voit päivittää viestintäasetuksesi milloin tahansa Oma tili -osiossa.',
  'marketing-preferences-cross-marketing-description':
    'Haluan saada eksklusiivisia tarjouksia <strong>kasinoverkostoltanne</strong>.',
  'marketing-preferences-contact-options-email': 'Haluan kuulla tarjouksista <strong>sähköpostitse</strong>',
  'marketing-preferences-contact-options-sms': 'Haluan kuulla tarjouksista <strong>tekstiviestitse</strong>',
  'marketing-preferences-contact-options-phone': 'Haluan kuulla tarjouksista <strong>puhelimitse</strong>',
  'marketing-preferences-contact-options-post': 'Haluan kuulla tarjouksista <strong>postin välityksellä</strong>',
  'terms-and-conditions-privacy-policy-and-funds-policy':
    'Vahvistan, että olen <span class="age-text">vähintään 18-vuotias</span> ja että hyväksyn <a href="/?page=terms" target="_blank" class="ui-link">käyttöehdot</a>, <a href="/?page=privacy" target= "_blank" class="ui-link">tietosuoja- ja evästekäytännön</a> sekä <a href="/?page=terms# funds-policy" target="_blank" class="ui-link">rahoituskäytännön</a>.',
  'sign-up': 'Luo tili',
};
