import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { forkJoin, fromEvent, Observable } from 'rxjs';

import { BaseComponent } from '../base.component';
import { TileSlider, TileSliderConfig, TileSliderContentType } from './tile-slider.model';
import { CoreService } from '../core-services/core.service';
import { GamesStore } from '../core-services/games/games.store';
import { PlayService } from '../core-services/play/play.service';
import { VisitorStore } from '../core-services/visitor/visitor.store';
import { DeviceType } from '../core-services/visitor/visitor.model';
import { Game } from '../core-services/games/games.model';
import { TileInterface } from '../tile/tile.model';
import { GameModeEnum } from '../core-services/play/play.model';
import { tiles } from './lounge-rooms.config';
import { FavoritesStore } from '../core-services/favorites/favorites.store';
import { TileComponent } from '../tile/tile.component';

export const TILE_SLIDER_SELECTOR = 'whg-tile-slider';
const TRUNCATE_TEXT_DEFAULT = true;
const TILE_WIDTH = 180;
const TILE_NUMBER = 12;

@Component({
  selector: TILE_SLIDER_SELECTOR,
  templateUrl: './tile-slider.component.html',
  styleUrls: ['./tile-slider.component.scss'],
})
export class TileSliderComponent extends BaseComponent implements OnInit {
  @Input() set config(config: string) {
    this.tileSliderConfig = JSON.parse(config);
    this.setConfig();
  }

  isFav = false;

  @ViewChild('slider') slider!: ElementRef;
  public arrowLeft = '/assets/icons/arrow-left.svg';
  public arrowRight = '/assets/icons/arrow-right.svg';
  public sliderScrollLeft = 0;
  public sliderScrollRight = 1;
  public isLoading = true;
  public isMobile = false;
  public isLoggedIn = false;
  public tileSlider!: TileSlider;
  public emptyTilesArray = Array(6).fill(0);
  private tileSliderConfig!: TileSliderConfig;
  private deviceType?: string;
  private countryCode = '';
  @ViewChildren('tiles') tilesList!: QueryList<TileComponent>;

  constructor(
    private coreService: CoreService,
    private gamesStore: GamesStore,
    private playService: PlayService,
    private visitorStore: VisitorStore,
    private favouritesStore: FavoritesStore
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeUser();
    this.requestInitialData();
    this.subscribeDeviceType();
  }
  resize() {
    if (this.tileSlider.truncate) {
      this.tilesList.forEach((tile) => tile.truncateText());
    }
  }

  public scrollRight(): void {
    this.subscribeSlider();
    if (this.deviceType === 'desktop') {
      this.slider.nativeElement.scrollTo({
        left: this.slider.nativeElement.scrollLeft + TILE_WIDTH * 3,
        behavior: 'smooth',
      });
    } else {
      this.slider.nativeElement.scrollTo({
        left: this.slider.nativeElement.scrollLeft + TILE_WIDTH,
        behavior: 'smooth',
      });
    }
  }

  public scrollLeft(): void {
    if (this.deviceType === 'desktop') {
      this.slider.nativeElement.scrollTo({
        left: this.slider.nativeElement.scrollLeft - TILE_WIDTH * 3,
        behavior: 'smooth',
      });
    } else {
      this.slider.nativeElement.scrollTo({
        left: this.slider.nativeElement.scrollLeft - TILE_WIDTH,
        behavior: 'smooth',
      });
    }
  }

  private subscribeUser(): void {
    this.visitorStore.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));
  }

  private subscribeDeviceType(): void {
    this.visitorStore.deviceType$.pipe(takeUntil(this.destroy$)).subscribe((deviceType) => {
      this.deviceType = deviceType;
      this.setIsMobile();
      this.getTiles();
    });
  }

  private requestInitialData(): void {
    this.isLoading = true;

    forkJoin([this.getDeviceTypeRequest(), this.getCountryCodeRequest()]).subscribe(([deviceType, countryCode]) => {
      this.deviceType = deviceType;
      this.countryCode = countryCode;
    });
  }

  private getTiles(): void {
    switch (this.tileSliderConfig.contentType) {
      case TileSliderContentType.GAMES:
        this.getGames();
        break;

      case TileSliderContentType.ROOMS:
        this.getRooms();
        break;

      case TileSliderContentType.FAVOURITES:
        this.isFav = true;
        this.getFavourites();
        break;

      case TileSliderContentType.WINS:
        break;
    }
    this.isLoading = false;
  }

  private getRooms(): void {
    tiles.forEach((tile: TileInterface) => (tile.image.src = tile.image.src.replace('%s', this.coreService.getCurrentLanguage())));
    this.tileSlider = Object.assign({}, this.tileSlider, { tiles });
  }

  private setConfig(): void {
    const { category, title, subTitle, link, truncate = TRUNCATE_TEXT_DEFAULT } = this.tileSliderConfig;
    this.tileSlider = Object.assign({}, this.tileSlider, { category, title, subTitle, link, truncate });
  }

  private getGames(): void {
    this.getGameCategory(this.tileSlider.category).subscribe((category) =>
      this.getGamesByCategory(category).subscribe((games) => {
        this.tileSlider.tiles = this.setTiles(games);

        if (tiles.some((tile) => tile.category === this.tileSlider.category)) {
          this.addGameCategory();
        }
      })
    );
  }

  private addGameCategory(): void {
    const categoryTile = tiles.find((tile) => tile.category === this.tileSlider.category);

    if (categoryTile) {
      this.tileSlider.tiles.push(categoryTile);
    }
  }

  private getFavourites(): void {
    this.favouritesStore.favorites$.pipe(takeUntil(this.destroy$)).subscribe((games) => {
      this.tileSlider.tiles = this.setTiles(Array.from(games));
      this.subscribeUser();
    });
  }

  private getGameCategory(category: string): Observable<string> {
    return this.gamesStore.getGamesCategory(category, this.countryCode, this.deviceType === DeviceType.Mobile).pipe(
      first(),
      map((gamesCategory) => gamesCategory)
    );
  }

  private getGamesByCategory(category: string): Observable<Game[]> {
    return this.gamesStore.getGamesOfCategory(category).pipe(
      takeUntil(this.destroy$),
      filter((x: Game[]) => !!x && x.length > 0),
      map((games) => games)
    );
  }

  private setTiles(games: Game[]): TileInterface[] {
    return games.slice(0, TILE_NUMBER).map((game) => {
      const { id, launchcode, name, provider, hot, new: n, image } = game;
      const mode = this.isLoggedIn ? GameModeEnum.Real : GameModeEnum.Fun;
      const extension = game.sub_category === 'animated' ? 'svg' : 'jpg';

      return {
        id,
        image: {
          src: `${this.coreService.scontentUrl}images/portrait/${image}.${extension}`,
          alt: name,
        },
        title: name,
        subTitle: provider,
        game,
        hot,
        new: n,
        link: this.playService.getGameUrl(launchcode, this.isLoggedIn, mode),
      };
    });
  }

  private subscribeSlider(): void {
    fromEvent(this.slider.nativeElement, 'scroll', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getSliderScrollPosition());
  }

  private getSliderScrollPosition(): void {
    this.sliderScrollLeft = this.slider.nativeElement.scrollLeft;
    this.sliderScrollRight =
      this.slider.nativeElement.scrollWidth -
      this.slider.nativeElement.scrollLeft -
      this.slider.nativeElement.getBoundingClientRect().width;
  }

  private getDeviceTypeRequest(): Observable<DeviceType | undefined> {
    return this.visitorStore.deviceType$.pipe(first());
  }

  private getCountryCodeRequest(): Observable<string> {
    return this.visitorStore.countryCode$.pipe(first());
  }

  private setIsMobile(): void {
    this.isMobile = this.deviceType === DeviceType.Mobile;
  }
}
