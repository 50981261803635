/* tslint:disable:max-line-length */
export default {
  'first-name-placeholder': 'Tu nombre va aquí...',
  'last-name-placeholder': 'Tu apellido va aquí...',
  'register-your-account-now': 'Registra tu cuenta ahora',
  'address-lookup-placeholder': 'Busca tu dirección...',
  'address-line1-placeholder': 'Tu dirección va aquí...',
  'address-line2-placeholder': 'Tu dirección va aquí...',
  'address-city-placeholder': 'Tu ciudad va aquí...',
  'address-lookup-town-city': 'ciudad',
  'address-zip-code-placeholder': 'Tu código postal va aquí...',
  'address-lookup-postal-code-zip': 'código postal',
  'address-lookup-enter-manually': `Introduce manualmente`,
  'email-placeholder': 'Tu correo electrónico va aquí...',
  'phone-placeholder': 'Tu número va aquí...',
  'username-placeholder': 'Tu nombre de usuario va aquí...',
  'password-placeholder': 'Tu contraseña va aquí...',
  'step-1-pretext': 'Paso 1 de 3',
  'step-2-pretext': 'Paso 2 de 3',
  'step-3-pretext': 'Paso 3 de 3',
  'step-1-post-text': 'Paso 1 de 3',
  'step-2-post-text': 'Paso 2 de 3 ',
  'step-3-post-text': 'Entra en la sala',
  'date-format': 'DD/MM/AAAA',
  phone: 'número de teléfono',
  'marketing-preferences-title': 'Preferencias de comunicación',
  'marketing-preferences-pre-text':
    'Vegas Lounge ofrece a sus jugadores ofertas únicas especiales Para recibir más información sobre estas ofertas, especifica tus preferencias de comunicación a continuación:',
  'marketing-preferences-post-text':
    'Puedes actualizar tus preferencias de comunicación en cualquier momento en la sección Mi Cuenta.',
  'marketing-preferences-cross-marketing-description':
    'Quiero conocer las ofertas exclusivas de su <strong>red de casinos</strong>.',
  'marketing-preferences-contact-options-email': 'Quiero recibir ofertas vía<strong>email</strong>',
  'marketing-preferences-contact-options-sms': 'Quiero recibir ofertas vía<strong>sms</strong>',
  'marketing-preferences-contact-options-phone': 'Quiero recibir ofertas a través del<strong>teléfono</strong>',
  'marketing-preferences-contact-options-post': 'Quiero conocer las ofertas a través del <strong>correo</strong>',
  'terms-and-conditions-privacy-policy-and-funds-policy':
    'Confirmo que tengo <span class="age-text">al menos 18 años</span> y que acepto los <a href="/?page=terms" target="_blank" class="ui-link">Términos y condiciones</a>, <a href="/?page=privacy" target="_blank" class="ui-link">Aviso de privacidad y cookies</a> y <a href="/?page=terms#funds-policy" target="_blank" class="ui-link">Política de fondos</a>.',
  'sign-up': 'Crear cuenta',
};
