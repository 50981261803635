import en from './en';
// import GB_en from "./gb/en";
// import FI_fi from "./fi/fi";
import { Translations, Countries } from '@whitehatgaming/shared/lib/translations';
import sv from './sv';
import no from './no';
import fr from './fr';
import fi from './fi';
import de from './de';
import es from './es';

export const countries: Countries = {
  // 'gb': {
  //   'en': GB_en
  // },
  // 'fi': {
  //   'fi': FI_fi
  // }
};
export const translations: Translations = {
  de: de,
  en: en,
  fi: fi,
  fr: fr,
  no: no,
  sv: sv,
  es: es,
  // 'zh-cn': zhCn
};
