/* tslint:disable:max-line-length */
export default {
  'first-name-placeholder': 'Skriv inn ditt fornavn her...',
  'last-name-placeholder': 'Skriv inn ditt etternavn her...',
  'register-your-account-now': 'Registrer en konto nå',
  'address-lookup-placeholder': 'Søk etter adressen din...',
  'address-line1-placeholder': 'Skriv inn adressen din her...',
  'address-line2-placeholder': 'Skriv inn adressen din her...',
  'address-city-placeholder': 'Skriv inn poststedet ditt her...',
  'address-lookup-town-city': 'poststed',
  'address-zip-code-placeholder': 'Skriv inn postnummeret ditt her...',
  'address-lookup-postal-code-zip': 'postnummer',
  'address-lookup-enter-manually': `Skriv inn manuelt`,
  'email-placeholder': 'Skriv inn e-postadressen din her...',
  'phone-placeholder': 'Skriv inn ditt nummer her...',
  'username-placeholder': 'Skriv inn ditt brukernavn her...',
  'password-placeholder': 'Skriv inn ditt passord her...',
  'step-1-pretext': 'Steg 1 av 3',
  'step-2-pretext': 'Steg 2 av 3',
  'step-3-pretext': 'Steg 3 av 3',
  'step-1-post-text': 'Steg 1 av 3',
  'step-2-post-text': 'Steg 2 av 3 ',
  'step-3-post-text': 'Gå inn i loungen',
  'date-format': 'DD/MM/ÅÅÅÅ',
  phone: 'mobilnummer',
  'marketing-preferences-title': 'Preferanser for kommunikasjon',
  'marketing-preferences-pre-text':
    'Vegas Lounge tilbyr sine spillere unike spesialtilbud. Spesifiser dine preferanser for kommunikasjon nedenfor for å motta mer info om disse tilbudene:',
  'marketing-preferences-post-text':
    'Du kan når som helst oppdatere dine preferanser for kommunikasjon fra «Min konto»-delen.',
  'marketing-preferences-cross-marketing-description':
    'Jeg ønsker å høre mer om deres eksklusive tilbud fra deres <strong>nettverk av casinoer</strong>.',
  'marketing-preferences-contact-options-email': 'Jeg ønsker å få vite om tilbud på <strong>e-post</strong>',
  'marketing-preferences-contact-options-sms': 'Jeg ønsker å få vite om tilbud på <strong>SMS</strong>',
  'marketing-preferences-contact-options-phone': 'Jeg ønsker å få vite om tilbud på <strong>telefon</strong>',
  'marketing-preferences-contact-options-post': 'Jeg ønsker å få vite om tilbud via <strong>post</strong>',
  'terms-and-conditions-privacy-policy-and-funds-policy':
    'Jeg bekrefter at jeg er <span class="age-text">18 år eller eldre</span> og jeg aksepterer <a href="/?page=terms" target="_blank" class="ui-link">vilkår og betingelser</a>, <a href="/?page=privacy" target="_blank" class="ui-link">retningslinjer om personvern og bruk av informasjonskapsler</a> og <a href="/?page=terms#funds-policy" target="_blank" class="ui-link">retningslinjer for midler</a>.',
  'sign-up': 'Opprett konto',
};
