// tslint:disable:member-ordering
// Disabled as grouping selector, updater & effects makes more sense.
import { Observable, combineLatest, of, from, pipe, take } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { CoreService } from '../core.service';
import { PlatformService } from '../platform/platform.service';
import { Category, Game, GameApiUrl, GameProvider, GamesState, TrendingSlot } from './games.model';

import { VisitorStore } from '../visitor/visitor.store';
import { GameStoreService } from '@whitehatgaming/shared';

@Injectable({
  providedIn: 'root',
})
export class GamesStore extends ComponentStore<GamesState> {
  category$: Observable<Category> | undefined;

  constructor(
    private http: HttpClient,
    private platformService: PlatformService,
    private coreService: CoreService,

    private visitorStore: VisitorStore,
    private gameStoreService: GameStoreService
  ) {
    super({ allGames: [], allProviders: [], categories: [], trendingSlots: [] });
  }

  /*
   * Selectors read state out of our component store
   */
  readonly allGames$: Observable<Game[]> = this.select((state) => state.allGames);
  readonly allGameProviders$: Observable<GameProvider[]> = this.select((state) => state.allProviders);
  readonly allGameCategories$: Observable<Category[]> = this.select((state) => state.categories);
  readonly tredingSlot$: Observable<TrendingSlot[]> = this.select((state) => state.trendingSlots);

  getGamesOfProviderBySeoName(provider: string): Observable<Game[]> {
    return this.select(({ allGames }) => allGames.filter((game) => game.provider.toLowerCase().split(' ').join('-') === provider));
  }

  getGamesOfCategory(category: string): Observable<Game[]> {
    return this.select(({ allGames }) =>
      allGames
        .filter((game) => game.categories.some((c) => c.category === category))
        .sort((a, b) => {
          const aSeq = a.categories.find((c) => c.category === category)?.seq;
          const bSeq = b.categories.find((c) => c.category === category)?.seq;
          if (aSeq !== undefined && bSeq !== undefined) {
            return aSeq - bSeq;
          }
          return 0;
        })
    );
  }

  /*
   * Updaters act as reducers as such and add values to our component state
   */
  readonly addGames = this.updater((state, allGames: Game[]) => ({
    ...state,
    allGames,
  }));

  readonly addGameProviders = this.updater((state, allProviders: GameProvider[]) => ({
    ...state,
    allProviders,
  }));

  readonly addCategories = this.updater((state, categories: Category[]) => ({
    ...state,
    categories,
  }));
  readonly addTrendingSlots = this.updater((state, trendingSlots: TrendingSlot[]) => ({
    ...state,
    trendingSlots,
  }));

  /*
   * Effects trigger side effects which manipulate the components state (we can also do this by calling .setState() in our component)
   * Effects are automatically subscribed to in the component they are called in for the life of the component
   */

  /*
   *  New to VLC games are retrieved from the category property from the cms (gamesJSON.php),
   *  Content managers can change content from the CMS like this, we can also call the cms from a middleware
   */

  readonly getTrendingSlots = this.effect(() => {
    return this.http.get<TrendingSlot[]>('/assets/trending-slot/trending-slot-content.json').pipe(
      tap((res) => {
        this.addTrendingSlots(res as TrendingSlot[]);
      })
    );
  });

  readonly getGames = this.effect(() => {
    let gamesList: Game[] = [];
    return combineLatest([this.visitorStore.jurisdiction$, this.visitorStore.countryCode$]).pipe(
      filter((value: any) => value[0] && value[1]),
      take(1),
      switchMap(([jurisdiction, countryCode]) =>
        this.gameStoreService.getAllGames(countryCode, jurisdiction).then((res) => {
          return this.gameStoreService.getAllCategories().then((categories) => {
            res.forEach((game) => {
              const gamesCategories = categories.filter((category) => {
                category.category = category.name;
                return game.categories.some((el) => el.id === category.id);
              });
              const newGame: Game = {
                date_added: game.game.updatedAt.toString(),
                description: game.game.description,
                fun_supported: game.game.funSupported ? 1 : 0,
                image: game.game.launchCode,
                jackpot: game.game.jackpot ? 1 : 0,
                launchcode: game.game.launchCode,
                max: game.game.maxStake,
                min: game.game.minStake,
                name: game.game.name,
                provider: game.game.providerTitle,
                rtp: game.game.rtp,
                seo_name: game.game.seoName,
                categories: gamesCategories,
                desktop: 0,
                game_seq: 0,
                gamelimit: 0,
                hot: 0,
                id: 0,
                mobile: 0,
                new: 0,
                type: game.game.gameType,
                typeid: 0,
                sub_category: '',
              };
              gamesList.push(newGame);
            });

            return of(gamesList);
          });
        })
      ),
      tap(() => this.addGames(gamesList))
    );
  });

  readonly getGameProviders = this.effect(() => {
    const observable = from(this.gameStoreService.getAllProviders());
    this.allGames$
      .pipe(
        takeUntil(this.destroy$),
        filter((x: Game[]) => !!x && x.length > 0)
      )
      .subscribe((games: Game[]) => {
        let providers: { [key: string]: number } = {};

        for (var key in games) {
          let provider = games[key].provider;
          if (!(provider in providers)) {
            providers[provider] = 0;
          }

          providers[provider]++;
        }

        let providersList: GameProvider[] = [];
        observable.subscribe((res) => {
          res.forEach((provider) => {
            if (providers[provider.title] !== undefined) {
              const newProvider: GameProvider = {
                title: provider.title,
                amount: providers[provider.title] + '',
                id: provider.id ?? 0,
              };
              const checkProvider = providersList.some(({ title }) => title == provider.title);
              if (!checkProvider) {
                providersList.push(newProvider);
              }
            }
          });

          this.addGameProviders(providersList);
        });
      });

    return observable;
  });

  readonly getCategories = this.effect(() => {
    const observable = from(this.gameStoreService.getAllCategories());
    this.allGames$
      .pipe(
        takeUntil(this.destroy$),
        filter((x: Game[]) => !!x && x.length > 0)
      )
      .subscribe((games: Game[]) => {
        let categories: { [key: string]: number } = {};

        for (var key in games) {
          for (var c in games[key].categories) {
            let name = games[key].categories[c].name ?? '';
            if (!(name in categories)) {
              categories[name] = 0;
            }
            categories[name]++;
          }
        }

        let categoriesList: Category[] = [];
        observable.subscribe((res) => {
          res.forEach((category) => {
            const newCategory: Category = {
              id: category.id,
              name: category.title,
              category: category.name,
              amount: categories[category.name] ?? 0,
              type: category.deviceType == 'Desktop' ? 'desktop' : 'mobile',
              country: category.country,
            };
            categoriesList.push(newCategory);
          });
          this.addCategories(categoriesList);
        });
      });

    return observable;
  });

  getGameByLaunchCode(launchCode: string): Observable<Game | undefined> {
    return this.select((state) => state.allGames.find((game) => game.launchcode === launchCode));
  }

  getGamesByLaunchCode(launchCodes: string[]): Observable<Game[]> {
    return this.select((state) => state.allGames.filter((game) => launchCodes.includes(game.launchcode)));
  }

  getGameBySeoName(seoName: string): Observable<Game | undefined> {
    return this.select((state) => state.allGames.find((game) => game.seo_name === seoName));
  }

  getGamesCategory(category: string, countryCode: string, isMobile: boolean): Observable<string> {
    if (isMobile && !category.startsWith('mobile')) {
      category = `mobile${category}`;
    }

    this.getCategories();
    return this.allGameCategories$.pipe(
      takeUntil(this.destroy$),
      filter((x) => !!x && x.length > 0),
      map((categories) => {
        const allCategories = categories.filter((cat) => cat.category === category);
        const countryCategory = allCategories.find((cat) => cat.category === `${category}_${countryCode.toLowerCase()}`);
        return countryCategory && countryCategory.category ? countryCategory.category : category;
      })
    );
  }
}
