export default {
  'first-name-placeholder': 'Votre  prénom va ici...',
  'last-name-placeholder': 'Votre nom va ici...',
  'register-your-account-now': 'Enregistrez votre compte maintenant',
  'address-lookup-placeholder': 'Recherchez votre adresse...',
  'address-line1-placeholder': 'Votre adresse va ici...',
  'address-line2-placeholder': 'Votre adresse va ici...',
  'address-city-placeholder': 'Votre ville va ici...',
  'address-lookup-town-city': 'ville',
  'address-zip-code-placeholder': 'Votre code postal va ici...',
  'address-lookup-postal-code-zip': 'code postal',
  'address-lookup-enter-manually': `Entrez manuellement`,
  'email-placeholder': 'Votre courriel va ici...',
  'phone-placeholder': 'Votre numéro va ici...',
  'username-placeholder': "Votre nom d'utilisateur va ici...",
  'password-placeholder': 'Votre mot de passe va ici...',
  'step-1-pretext': 'Étape 1 de 3',
  'step-2-pretext': 'Étape 2 de 3',
  'step-3-pretext': 'Étape 3 de 3',
  'step-1-post-text': 'Étape 1 de 3',
  'step-2-post-text': 'Étape 2 de 3',
  'step-3-post-text': 'Entrez dans le Lounge',
  'date-format': 'JJ/MM/AAAA',
  phone: 'numéro de téléphone portable',
  'marketing-preferences-title': 'Préférences de communication',
  'marketing-preferences-pre-text':
    'Vegas Lounge offre à ses joueurs des offres spéciales uniques. Pour recevoir de plus amples informations sur ces offres, veuillez préciser vos préférences de communication ci-dessous :',
  'marketing-preferences-post-text':
    'Vous pouvez mettre à jour vos préférences de communication à tout moment dans la section Mon compte.',
  'marketing-preferences-cross-marketing-description':
    'Je veux recevoir des offres exclusives de votre <strong>réseau de casino</strong>',
  'marketing-preferences-contact-options-email': 'Je veux en savoir plus sur les offres par <strong>courriel</strong>',
  'marketing-preferences-contact-options-sms': 'Je veux entendre parler des offres via <strong>message texte</strong>',
  'marketing-preferences-contact-options-phone': 'Je veux en savoir plus sur les offres par <strong>téléphone</strong>',
  'marketing-preferences-contact-options-post': 'Je veux en savoir plus sur les offres par <strong>courrier</strong>',
  'terms-and-conditions-privacy-policy-and-funds-policy':
    "Je confirme que j'ai <span class='age-text'>au moins 18 ans</span> et que j'accepte les <<a href='/?page=terms' target='_blank' class='ui-link'>Conditions générales</a>, <a href='/?page=privacy' target='_blank' class='ui-link'>Avis de confidentialité et de témoins de connexion<a href='/?page=terms#funds-policy' target='_blank' class='ui-link'>Politique des fonds</a>.",
  'sign-up': 'Créer un compte',
};
